import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { auth } from "./firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  setPersistence,
  inMemoryPersistence,
} from "firebase/auth";

export const AuthContext = createContext(null);

const INACTIVITY_TIMEOUT = 30 * 60 * 1000;

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const inactivityTimerRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(logout, INACTIVITY_TIMEOUT);
  };

  const checkSession = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/check-session`, {
        credentials: "include",
      });

      if (response.ok) {
        const userData = await response.json();
        setCurrentUser({
          uid: userData.uid,
          email: userData.email,
          name: userData.name,
        });
        setUserRole(userData.role);
        setIsAuthenticated(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error("Session check failed:", error);
      return false;
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const sessionValid = await checkSession();
        if (!sessionValid) {
          const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
              const token = await user.getIdTokenResult(true);
              setUserRole(
                token.claims.admin ? "admin" : token.claims.role || "לקוח"
              );
              setCurrentUser(user);
              setIsAuthenticated(true);
              resetInactivityTimer();
            } else {
              setCurrentUser(null);
              setUserRole(null);
              setIsAuthenticated(false);
              if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
              }
            }
            setLoading(false);
          });
          return () => unsubscribe();
        }
      } catch (error) {
        console.error("Auth initialization failed:", error);
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
      
      const resetTimer = () => {
        resetInactivityTimer();
      };

      events.forEach(event => {
        document.addEventListener(event, resetTimer);
      });

      resetInactivityTimer();

      return () => {
        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
        }
        events.forEach(event => {
          document.removeEventListener(event, resetTimer);
        });
      };
    }
  }, [isAuthenticated]);

  const login = async (email, password) => {
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken();
  
      const response = await fetch(`${apiUrl}/auth/session-login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({ token: idToken }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Session creation failed");
      }
  
      const userData = await response.json();
      const token = await userCredential.user.getIdTokenResult();
  
      setCurrentUser({
        uid: userCredential.user.uid,
        email: userCredential.user.email,
        name: userCredential.user.displayName,
        ...userData.additionalUserData,
      });
  
      const role = token.claims.admin ? "admin" : token.claims.role || "לקוח";
      setUserRole(role);
      setIsAuthenticated(true);
  
    
      return { userCredential, role, userData };
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const logout = async () => {
    try {
      await setPersistence(auth, inMemoryPersistence);
      await signOut(auth);

      await fetch(`${apiUrl}/auth/session-logout`, {
        method: "POST",
        credentials: "include",
      });

      setCurrentUser(null);
      setUserRole(null);
      setIsAuthenticated(false);

      if (window.indexedDB) {
        const databases = await window.indexedDB.databases();
        databases.forEach((db) => window.indexedDB.deleteDatabase(db.name));
      }

      localStorage.clear();
      sessionStorage.clear();

      window.location.href = "/";
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const signup = async (email, password) => {
    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      resetInactivityTimer();
    } finally {
      setLoading(false);
    }
  };

  const value = {
    currentUser,
    userRole,
    login,
    logout,
    loading,
    isAuthenticated,
    checkSession,
    signup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
