import React, { useState, useEffect } from "react";
import { Card, List, Button, message, Modal, Spin, Pagination } from "antd";
import {
  EyeOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  LoadingOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const SecurePDFViewer = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewerVisible, setViewerVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await fetch(`${apiUrl}/pdf-handler/get-files`);
      if (!response.ok) throw new Error("Failed to fetch files");
      const data = await response.json();
      setFiles(data);
    } catch (error) {
      message.error("Failed to load files");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async (fileId) => {
    try {
      const response = await fetch(
        `${apiUrl}/pdf-handler/get-files/${fileId}/view`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
          credentials: "include",
        },
      );

      if (!response.ok) {
        throw new Error("Failed to download resume");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${fileId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      message.error("שגיאה בהורדת מסמך");
    }
  };


  const handleZoomIn = () => {
    setScale((prev) => Math.min(prev + 0.2, 2.0));
  };

  const handleZoomOut = () => {
    setScale((prev) => Math.max(prev - 0.2, 0.5));
  };

  const SecureViewer = ({ fileId }) => {
    const viewerUrl = `${apiUrl}/pdf-handler/get-files/${fileId}/view`;
    const [pageLoading, setPageLoading] = useState({});

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    const handlePageLoadSuccess = (pageNumber) => {
      setPageLoading((prev) => ({ ...prev, [pageNumber]: false }));
    };

    const handlePageLoadStart = (pageNumber) => {
      setPageLoading((prev) => ({ ...prev, [pageNumber]: true }));
    };

    
    return (
      <div className="flex flex-col h-full">
        {/* PDF Viewer Container */}
        <div className="flex-1 overflow-auto min-h-0 relative">
          <div className="flex justify-center">
            <div className="w-full max-w-5xl">
              <Document
                file={viewerUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={
                  <div className="flex justify-center items-center h-40">
                    <Spin size="large" />
                  </div>
                }
                className="flex flex-col items-center"
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <div key={`page_${index + 1}`} className="mb-8 relative">
                    {pageLoading[index + 1] && (
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-100/75">
                        <Spin
                          indicator={
                            <LoadingOutlined className="text-2xl" spin />
                          }
                        />
                      </div>
                    )}
                    <Page
                      pageNumber={index + 1}
                      scale={scale}
                      className="shadow-lg"
                      loading={
                        <div className="h-[800px] w-full bg-gray-100 animate-pulse" />
                      }
                      onLoadSuccess={() => handlePageLoadSuccess(index + 1)}
                      onRenderAnnotationLayerSuccess={() =>
                        handlePageLoadSuccess(index + 1)
                      }
                      onRenderTextLayerSuccess={() =>
                        handlePageLoadSuccess(index + 1)
                      }
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      onLoadStart={() => handlePageLoadStart(index + 1)}
                    />
                    <div className="text-center mt-2 text-gray-500 text-sm">
                      עמוד {index + 1} מתוך {numPages}
                    </div>
                  </div>
                ))}
              </Document>
            </div>
          </div>
        </div>

        {/* Controls Panel */}
        <div className="sticky bottom-0 flex items-center justify-end px-4 py-3 border-t bg-gray-50/90 backdrop-blur-sm">
          <div className="space-x-2">
            <Button
              icon={<ZoomInOutlined />}
              onClick={handleZoomIn}
              disabled={scale >= 2.0}
              type="default"
            />
            <Button
              icon={<ZoomOutOutlined />}
              onClick={handleZoomOut}
              disabled={scale <= 0.5}
              type="default"
            />
            <span className="text-gray-600 mx-2">
              {Math.round(scale * 100)}%
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      {/* File List */}
      <Card
        title={
          <div className="flex justify-between items-center">
            <h1 className="text-lg text-gray-900 font-bold">
              {" "}
              מסמכי ואישורי החברה
            </h1>
            <span className="text-sm text-gray-700">
              סה"כ מסמכים: {files.length}
            </span>
          </div>
        }
        className="shadow-lg"
        styles={{ padding: "0" }}
      >
        <List
          loading={loading}
          dataSource={files.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )}
          className="divide-y"
          renderItem={(file) => (
            <List.Item
              className="hover:bg-gray-50 transition-colors"
              actions={[
                <Button
                  type="primary"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setSelectedFileId(file._id);
                    setViewerVisible(true);
                    setScale(1.0);
                  }}
                  className="text-base"
                >
                  צפה במסמך
                </Button>,
                  <Button
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownloadFile(file._id)}
                >
                  הורד מסמך
                </Button>
              ]}
            >
              <List.Item.Meta
                title={
                  <span className="text-lg text-gray-800 font-semibold">
                    {file.title}
                  </span>
                }
              />
            </List.Item>
          )}
        />
        {files.length > pageSize && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={files.length}
            onChange={(page) => setCurrentPage(page)}
            onShowSizeChange={(current, size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
            className="mt-4"
          />
        )}
      </Card>

      {/* PDF Viewer Modal */}
      <Modal
        title={
          <h2 className="text-lg font-bold">
            צפה במסמך {files.find((f) => f._id === selectedFileId)?.title || ""}
          </h2>
        }
        open={viewerVisible}
        onCancel={() => {
          setViewerVisible(false);
          setSelectedFileId(null);
        }}
        centered
        footer={null}
        width="90%"
        style={{
          top: 20,
          maxHeight: "80vh",
          padding: 0,
        }}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "70vh", // Adjust as needed for the content
          },
        }}
        destroyOnClose
      >
        {selectedFileId && <SecureViewer fileId={selectedFileId} />}
      </Modal>
    </div>
  );
};

export default SecurePDFViewer;
