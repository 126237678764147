import React, { useState, useContext } from "react";
import { Button, Form, Input, Typography, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { AuthContext } from "../auth/AuthContext";
import nraLogo from "../nra logo.webp";
const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  const handleNavigation = (role) => {
    switch (role) {
      case "admin":
        navigate("/dashboard/");
        break;
      case "מזכירות":
        navigate("/dashboard/job-management-list");
        break;
      case "לקוח":
        navigate("/documents");
        break;
      case "מכירות":
        navigate("/dashboard/contact-employers-leads");
        break;
      case "מנהל עובדים":
        navigate("/dashboard/announcement-transport-view");
        break;
      default:
        navigate("/");
    }
  };

  const onLogin = async (values) => {
    setLoading(true);
    setError("");

    try {
      const { userCredential, role } = await login(
        values.email,
        values.password
      );

      handleNavigation(role);
    } catch (e) {
      if (e instanceof FirebaseError) {
        const errorMessages = {
          "auth/invalid-email": "כתובת אימייל לא תקינה",
          "auth/user-disabled": "המשתמש מושבת",
          "auth/user-not-found": "משתמש לא נמצא",
          "auth/wrong-password": "סיסמה שגויה",
        };

        const errorMessage = errorMessages[e.code] || e.message;
        setError(errorMessage);
        message.error(errorMessage);
      } else {
        const errorMessage = "אירעה שגיאה, אנא נסה שוב מאוחר יותר";
        setError(errorMessage);
        message.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="font-[sans-serif] bg-white flex items-center justify-center md:h-screen p-4">
        <div className="shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)] max-w-6xl max-md:max-w-lg rounded-md p-6">
          <a >
            <img src={nraLogo} alt="לוגו NRA" className="w-40 md:mb-4 mb-12" />
          </a>

          <div className="grid md:grid-cols-2 items-center gap-8">
            <div className="max-md:order-1 lg:min-w-[450px]">
              <img
                src="https://readymadeui.com/signin-image.webp"
                className="lg:w-11/12 w-full object-cover "
                alt="login-image"
              />
            </div>

            <div className="max-w-md w-full space-y-8 bg-white p-8 rounded-lg shadow">
              <Spin spinning={loading} tip="מתחבר...">
                <div className="w-full">
                  <Title level={2} className="text-center">
                    התחבר
                  </Title>
                  <Text className="text-center mb-4">
                    יש למלא כתובת מייל וסיסמא
                  </Text>
                  <Form onFinish={onLogin} layout="vertical">
                    <Form.Item
                      name="email"
                      rules={[{ required: true, message: 'אנא הכנס דוא"ל' }]}
                    >
                      <Input
                        type="email"
                        placeholder="דואר אלקטרוני"
                        className="rounded-md border-gray-300"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[{ required: true, message: "אנא הכנס סיסמה" }]}
                    >
                      <Input.Password
                        placeholder="סיסמה"
                        className="rounded-md border-gray-300"
                      />
                    </Form.Item>
                    {error && <div className="text-red-500 mb-4">{error}</div>}
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="w-full"
                        loading={loading}
                      >
                        התחבר
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
