import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./auth/AuthContext";
import { LanguageProvider } from "./language/LanguageContext";

const rootElement = document.getElementById("root");
const app = (
  <AuthProvider>
    <Router>
      <React.StrictMode>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </React.StrictMode>
    </Router>
  </AuthProvider>
);

createRoot(rootElement).render(app);

reportWebVitals();