import { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext({
  language: 'he',
  handleLanguageChange: () => {},
});

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language') || 'he';
    return savedLanguage;
  });

  const handleLanguageChange = (newLang) => {
    console.log('handleLanguageChange called with:', newLang);
    setLanguage(newLang);
    localStorage.setItem('language', newLang);
    document.documentElement.dir = 'rtl';
    document.documentElement.lang = newLang;
  };

  // Add this useEffect to track language changes
  useEffect(() => {
  }, [language]);

  const contextValue = {
    language,
    handleLanguageChange,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};