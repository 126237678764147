import "./App.css";
import { React, useEffect, lazy, Suspense } from "react";
import { ConfigProvider, Layout, Spin } from "antd"; // Import Layout from antd
import { useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import Footer from "./pages/Footer";
import SecurePDFViewer from "./components/SecurePDFViewer"; // Add this import
import Login from "./pages/Login";
//auth
import { AuthProvider } from "./auth/AuthContext";
import PrivateRoute from "./auth/PrivateRoute";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const AccessibilityStatement = lazy(() =>
  import("./pages/AccessibilityStatement")
);

function App() {
  const location = useLocation();
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <AuthProvider>
      <ConfigProvider
        direction="rtl"
        theme={{
          token: {
            colorPrimary: "#f59e0b",
            colorLinkHover: "#d97706",
            colorLink: "#fbbf24",
          },
        }}
      >
        <Layout
          className="App body"
          style={{ direction: "rtl", minHeight: "100vh" }}
        >
          <div className="content" style={{ flex: 1 }}>
            <Suspense fallback={<Spin size="large" />}>
              <Routes>
                <Route>
                  <Route path="/" element={<Login />} />
                </Route>

                <Route>
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/accessibility-statement"
                    element={<AccessibilityStatement />}
                  />
                </Route>
                <Route
                  path="/dashboard/*"
                  element={
                    <PrivateRoute
                      component={Dashboard}
                      allowedRoles={["admin", "מזכירות", "מכירות", "מנהל עובדים"]}
                    />
                  }
                />
                <Route
                  path="/documents"
                  element={
                    <PrivateRoute
                      component={SecurePDFViewer}
                      allowedRoles={["admin", "לקוח"]}
                    />
                  }
                />
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </Layout>
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
