import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { Spin } from "antd";

const PrivateRoute = ({ component: Component, allowedRoles = [] }) => {
  const { currentUser, loading, userRole, isAuthenticated } =
    useContext(AuthContext);
  const location = useLocation();

  if (loading && !isAuthenticated && !currentUser) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large"  />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const hasRequiredRole =
    allowedRoles.length === 0 || allowedRoles.includes(userRole);

  if (!hasRequiredRole) {
    return <Navigate to="/" replace />;
  }

  return <Component />;
};

export default PrivateRoute;
